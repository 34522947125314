<template>
  <!-- ce component génère un SVG qui correspond aux paramètres et à l’image demandée -->
  <div class="m_svgpattern" ref="patternContainer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="globalCanvasSize.width"
      :height="globalCanvasSize.height"
      :data-patternwidth="width"
      :data-patternheight="height"
      ref="pattern"
      class=""
      :class="{
        'has--grid': grid.enabled,
        'is--animated': shapes.length < 2000,
      }"
    >
      <g id="shapes" ref="shapes" clip-path="url(#cut-off-bottom)">
        <defs>
          <clipPath id="cut-off-bottom">
            <rect
              id="background_rect"
              :x="-offset / 2"
              :y="-offset / 2"
              :width="width + offset"
              :height="height + offset"
              fill="transparent"
              stroke="#333"
            />
          </clipPath>
        </defs>
        <!-- <circle cx="100" cy="100" r="500" clip-path="url(#cut-off-bottom)" /> -->
        <template v-for="(attr, index) in current_shapes">
          <template v-if="attr.width > 0 && attr.height > 0">
            <line
              v-if="attr.type === 'line'"
              :key="index"
              :x1="-attr.width / 2"
              :y1="0"
              :x2="attr.width / 2"
              :y2="0"
              :stroke-width="attr.height"
              :stroke="attr.fill"
              :transform="`translate(${attr.posX}, ${attr.posY}) rotate(${attr.angle} 0 0)`"
            />

            <rect
              v-else-if="attr.type === 'rect'"
              :key="index"
              :x="-attr.width / 2"
              :y="-attr.height / 2"
              :width="attr.width"
              :height="attr.height"
              :fill="attr.fill"
              :transform="`translate(${attr.posX}, ${attr.posY}) rotate(${attr.angle} 0 0)`"
            />

            <rect
              v-else-if="attr.type === 'square'"
              :key="index"
              :x="-attr.height / 2"
              :y="-attr.height / 2"
              :width="attr.height"
              :height="attr.height"
              :fill="attr.fill"
              :transform="`translate(${attr.posX}, ${attr.posY}) rotate(${attr.angle} 0 0)`"
            />

            <circle
              v-else-if="attr.type === 'circle'"
              :key="index"
              :r="attr.height / 2"
              :fill="attr.fill"
              :transform="`translate(${attr.posX}, ${attr.posY})`"
            />

            <g
              v-else-if="attr.type === 'custom'"
              :key="index"
              :fill="attr.fill"
              :transform="`
              translate(${attr.posX - attr.width / 5}, ${
                attr.posY - attr.height / 5
              })
              rotate(${attr.angle} 0 0)
              scale(${attr.width / 10}, ${attr.height / 10})
              `"
              v-html="custom_path"
            >
              <!-- <rect
              :x="-10"
              :y="-10"
              :width="10"
              :height="10"
              :fill="attr.fill"
            /> -->
              <!-- <polygon 
              points="-10,-10 0,-5 10,-10 10,10 -10,10" 
            /> -->
            </g>
          </template>
        </template>
        <!-- <rect
          id="background_rect"
          :x="-offset / 2"
          :y="-offset / 2"
          :width="width + offset"
          :height="height + offset"
          fill="transparent"
          fill-opacity="100%"
          stroke="#333"
        /> -->
      </g>
      <g
        id="palette"
        v-if="shapes_palette.length > 0"
        :transform="`translate(${globalCanvasSize.width - 280}, ${
          globalCanvasSize.height - 150
        })`"
      >
        <rect
          id="background_rect"
          x="0"
          y="0"
          width="150"
          height="20"
          stroke="#000"
          stroke-width="2px"
        ></rect>
        <template v-for="(col, index) in shapes_palette">
          <rect
            x="0"
            y="0"
            :key="index"
            :width="`${150 / shapes_palette.length}`"
            height="20"
            :fill="`rgb(${col[0]},${col[1]},${col[2]})`"
            :stroke="`rgb(${col[0]},${col[1]},${col[2]})`"
            stroke-width="1px"
            :transform="`translate(${
              (150 / shapes_palette.length) * index
            }, 0)`"
          ></rect>
        </template>
      </g>
    </svg>
    <div class="m_svgpattern--buttons padding-sides-small">
      <div class="m_svgpattern--buttons--encours" v-if="$root.is_updating">
        updating…
      </div>
      <button type="button" class="btn_small" @click="zoom.zoomOut()">-</button>
      <button type="button" class="btn_small" @click="zoom.zoomIn()">+</button>
      <button type="button" class="btn_small" @click="zoom.resetZoom()">
        RESET
      </button>
      <button
        type="button"
        class="btn_small"
        :class="{ active: grid.enabled }"
        @click="grid.enabled = !grid.enabled"
      >
        GRID
      </button>
      <button
        type="button"
        class="btn_small"
        @click="show_saveload_modal = true"
      >
        SAVE/EXPORT
      </button>
      <SaveLoadModal
        v-if="show_saveload_modal"
        :grid_is_enabled="grid.enabled"
        :values="values"
        @close="show_saveload_modal = false"
      />
    </div>
  </div>
</template>
<script>
import SaveLoadModal from "./SaveLoadModal.vue";

import * as d3 from "d3";

export default {
  props: {
    values: Object,
    width: Number,
    height: Number,
    offset: Number,
    shapes: {
      type: Array,
      default: () => [],
    },
    shapes_palette: {
      type: Array,
      default: () => [],
    },
    // custom_path: {
    //   type: String,
    //   default: ''
    // },
    url_to_svg: {
      type: String,
      default: "",
    },
  },
  components: { SaveLoadModal },
  data() {
    return {
      current_shapes: [],
      d3svg: "",
      custom_path: undefined,
      grid: {
        increment: 25,
        enabled: false,
      },
      globalCanvasSize: {
        width: 900,
        height: 900,
      },
      update: {
        index: 0,
        maxSpeed: {
          items: 14000,
          percent: 0.3,
        },
        routine: null,
      },
      ui: {
        popup_advanced: false,
      },

      show_saveload_modal: false,
      zoom: {
        zoomIn: () => {},
        zoomOut: () => {},
        resetZoom: () => {},
      },
    };
  },
  created() {},
  mounted() {
    this.handleResize();
    this.loadCustomMotif();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    shapes: function () {
      if (this.shapes.length === 0) {
        return;
      }
      this.update.index = 0;
      clearTimeout(this.update.routine);

      this.$nextTick(() => {
        this.updateLocalShapesArrayProgressively();
      });
    },
    width: function () {
      this.d3svg = "";
      this.setupPanZoom();
    },
    height: function () {
      this.d3svg = "";
      this.setupPanZoom();
    },
    url_to_svg: function () {
      this.loadCustomMotif();
    },
  },
  computed: {},
  methods: {
    loadCustomMotif() {
      // fetch(this.url_to_svg)
      //   .then((response) => response.json())
      //   .then((json) => {
      //     console.log(`ImageCollection / mounted: gotten images`);
      //     console.log(`${JSON.stringify(json, null, 4)}`);
      //     this.image_collection = json;
      //     if (this.local_selected_image_name === "") {
      //       this.local_selected_image_name = this.image_collection[0].url;
      //     }
      //   })
      //   .catch(function (ex) {
      //     console.log("parsing failed", ex);
      //   });
      // $.get(
      //   this.url_to_svg,
      //   (data) => {
      //     const SVG = $.parseXML(data);
      //     this.custom_path = SVG.children[0].innerHTML;
      //   },
      //   "text"
      // );
    },
    handleResize() {
      this.globalCanvasSize.width = this.$refs.patternContainer.offsetWidth;
      this.globalCanvasSize.height = this.$refs.patternContainer.offsetHeight;
    },

    updateLocalShapesArrayProgressively() {
      // console.log(`PatternSvg / updateLocalShapesArrayProgressively`);
      // update current_shapes to match this.shapes, but maximum 50 items at a time
      // set destination index

      // find what’s bigger : percents or items
      let numberOfItemsToCopyFromPercent =
        this.update.maxSpeed.percent * this.shapes.length;
      let numberOfItemsToCopy = Math.floor(
        Math.min(numberOfItemsToCopyFromPercent, this.update.maxSpeed.items)
      );

      let indexToCopyTo = this.update.index + numberOfItemsToCopy;
      if (indexToCopyTo > this.shapes.length - 1) {
        indexToCopyTo = this.shapes.length - 1;
      }

      // console.log(`PatternSvg / updateLocalShapesArrayProgressively — now copying ${this.update.index} to ${indexToCopyTo}`);
      let shapesPartToCopy = this.shapes.slice(
        this.update.index,
        indexToCopyTo
      );
      this.current_shapes.splice(
        this.update.index,
        numberOfItemsToCopy,
        ...shapesPartToCopy
      );

      if (indexToCopyTo < this.shapes.length - 1) {
        this.update.index = indexToCopyTo;
        // console.log(`PatternSvg / updateLocalShapesArrayProgressively — will continue updating`);
        this.update.routine = requestAnimationFrame(
          this.updateLocalShapesArrayProgressively
        );
        // this.update.routine = setTimeout(this.updateLocalShapesArrayProgressively, 500);
      } else {
        // console.log(`PatternSvg / updateLocalShapesArrayProgressively — finished updating`);
        // let’s copy the array to make sure we have it right
        this.current_shapes = this.shapes.slice();
        this.setupPanZoom();

        this.$root.is_updating = false;
        // setTimeout(this.setupPanZoom, 600);
      }
    },

    setupPanZoom() {
      // this.d3svg exists to make sure we don’t reload d3 for each change
      // but only setup once, then recreate it when pattern width and height changes
      if (this.d3svg === "loaded") {
        return;
      }
      this.d3svg = "loaded";

      console.log("PatternSvg / setupPanZoom");

      let _zoom = d3.zoom();
      let svg = d3.select(this.$refs.pattern);
      let shapes = d3.select(this.$refs.shapes);

      svg.select(".axis--x").remove();
      svg.select(".axis--y").remove();

      var x = d3
        .scaleLinear()
        .domain([-1, this.globalCanvasSize.width + 1])
        .range([-1, this.globalCanvasSize.width + 1]);

      var y = d3
        .scaleLinear()
        .domain([-1, this.globalCanvasSize.height + 1])
        .range([-1, this.globalCanvasSize.height + 1]);

      var xAxis = d3
        .axisBottom(x)
        .ticks(
          ((this.globalCanvasSize.width + 2) /
            (this.globalCanvasSize.height + 2)) *
            10
        )
        .tickSize(this.globalCanvasSize.height)
        .tickPadding(8 - this.globalCanvasSize.height);

      var yAxis = d3
        .axisRight(y)
        .ticks(10)
        .tickSize(this.globalCanvasSize.width)
        .tickPadding(8 - this.globalCanvasSize.width);

      var gX = svg
        .insert("g", "#shapes + *")
        .attr("class", `grid axis axis--x`)
        .attr("id", "grid_x")
        .attr(this.$options._scopeId, "")
        .style("text-anchor", "start")
        // .style("transform-origin", "50% 50% 0")
        .call(xAxis);

      var gY = svg
        .insert("g", "#shapes + *")
        .attr("class", "grid axis axis--y")
        .attr("id", "grid_y")
        .attr(this.$options._scopeId, "")
        .style("text-anchor", "start")
        // .style("transform-origin", "50% 50% 0")
        .call(yAxis);

      // shapes_and_palette.style("transform-origin", "50% 50% 0");

      // calculate necessary scale

      let margins = 200;
      let minimumScale = Math.min(
        this.globalCanvasSize.height / (this.height + margins * 2),
        this.globalCanvasSize.width / (this.width + margins * 2)
      );

      svg.call(
        _zoom
          .scaleExtent([minimumScale / 2, 50])
          .translateExtent([
            [0 - margins, 0 - margins],
            [this.width + margins, this.height + margins],
          ])
          .on("zoom", zoomed)
      );

      function zoomed() {
        shapes.attr("transform", d3.event.transform);
        gX.call(xAxis.scale(d3.event.transform.rescaleX(x)));
        gY.call(yAxis.scale(d3.event.transform.rescaleY(y)));
      }

      this.zoom.zoomIn = () => {
        svg.transition().duration(250).call(_zoom.scaleBy, 2);
      };
      this.zoom.zoomOut = () => {
        svg.transition().duration(250).call(_zoom.scaleBy, 0.5);
      };

      let moveToX =
        (this.globalCanvasSize.width - this.width * minimumScale) / 2;
      let moveToY =
        (this.globalCanvasSize.height - this.height * minimumScale) / 2;

      this.zoom.resetZoom = () => {
        svg
          .transition()
          .duration(250)
          .call(
            _zoom.transform,
            d3.zoomIdentity.translate(moveToX, moveToY).scale(minimumScale)
          );
      };

      this.zoom.resetZoom();
    },
    zoomIn() {},
    zoomOut() {},
  },
};
</script>
<style lang="scss">
.m_svgpattern {
  width: 100%;
  height: 100%;
  display: block;
  margin-left: -1px;
  margin-top: -1px;

  // overflow: auto;

  // $scrollbar-height: 3px;
  // $scrollbar-padding: 10px;

  // &::-webkit-scrollbar {
  //   height: (($scrollbar-padding * 2) + $scrollbar-height);
  //   width: (($scrollbar-padding * 2) + $scrollbar-height);
  //   background-color: rgba(255,255,255,0);
  // }

  // &::-webkit-scrollbar-track,
  // &::-webkit-scrollbar-thumb {
  //   border: 10px solid rgba(255,255,255,0);
  //   background-clip: padding-box;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #ccc;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #212121;
  //   &:hover {
  //     border: $scrollbar-padding solid rgba(255,255,255,0);
  //   }
  // }

  > svg {
    cursor: move;
    width: 100%;
    height: 100%;
    margin: 0;
    border: none;
    background-color: transparent;

    .grid {
      display: none;
    }
    &.has--grid {
      .grid {
        display: block;
      }
    }
  }

  .m_svgpattern--buttons {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    background-color: #fff;
    border: 1px solid black;

    button {
      min-width: 1rem;
      margin: 0.5rem;
      color: #000;
      // font-weight: bold;
    }

    .popup_advanced {
      .close_button {
      }
    }
  }
}
</style>
<style lang="scss">
.m_svgpattern svg {
  .grid {
    line {
      stroke: #c9c9c9;
    }
    text {
      fill: #c9c9c9;
    }
    &#grid_y text {
      transform: translate(0, 10px);
    }

    &#grid_x text {
      transform: translate(5px, 0px);
    }
  }

  &.is--animated #shapes rect {
    transition-property: all;
    transition-duration: 1s;
  }
}
</style>
