<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div class="modal-container">
          <div class="modal-header">save and load from server</div>

          <div class="modal-body">
            <div class="_buttonRow">
              <!-- <div class="_buttonRowWrap"> -->
              <!-- <label>current pattern</label> -->
              <button type="button" class="btn_small" @click="saveSVG()">
                • DOWNLOAD CURRENT PATTERN SVG
              </button>
              <!-- <div class="_buttonRow--preview">
                  <div ref="current_pattern_preview" />
                </div> -->
              <div class="_buttonRow--pu">
                <label for="url">• Pattern URL</label>
                <input
                  type="url"
                  name="url"
                  id="url"
                  :value="pattern_URL"
                  size="30"
                />
                <!-- </div> -->

                <div></div>
              </div>
            </div>
            <div>
              <!-- <button type="button" class="btn_small" @click="load">
                Load all saved patterns
              </button> -->

              <!-- <select
                v-if="sorted_patterns.length"
                v-model="current_page"
                class="margin-verysmall select-xs"
                style="flex: 0 1 100px"
              >
                <option
                  v-for="page_number in number_of_possible_pages"
                  :key="page_number"
                  v-html="page_number"
                />
              </select> -->

              <div v-if="is_loading_patterns" class="loader"></div>

              <template v-else>
                <div class="_patterns">
                  <div
                    key="savePattern"
                    class="_patterns--saveButton"
                    v-if="save_status !== 'saved'"
                  >
                    <template v-if="!save_status">
                      <button type="button" class="btn_small" @click="save">
                        SAVE CURRENT
                      </button>
                    </template>
                    <template v-else-if="save_status === 'saving'">
                      <small>SAVING…</small>
                    </template>
                    <!-- <template v-else-if="">
                    <small>PATTERN SAVED</small>
                  </template> -->
                    <template v-else>
                      {{ save_status }}
                    </template>
                  </div>

                  <div
                    v-for="pattern in patterns_paginated"
                    :key="pattern.timestamp"
                  >
                    <!-- {{ index }} -->
                    <div class="_pattern--visuel">
                      <div>
                        <img class="" :src="pattern.url" />
                      </div>
                    </div>
                    <div class="_pattern--timestamp">
                      <!-- <span>#{{ pattern.timestamp }}</span> -->
                      <span>{{
                        new Date(pattern.timestamp * 1000).toLocaleString()
                      }}</span>
                    </div>
                    <!-- <hr /> -->
                    <div class="_pattern--links">
                      <a class="btn_small" :href="pattern.pattern_url">
                        Load
                      </a>
                      <!-- <a
                        class="btn_small"
                        :href="pattern.url"
                        download
                        target="_blank"
                      >
                        Download
                      </a> -->
                    </div>
                    <!-- <button
                      type="button"
                      class="btn_small"
                      @click="removePattern(pattern.url)"
                    >
                      Remove
                    </button> -->
                  </div>
                </div>

                <div class="flex-wrap flex-no-grow flex-horizontally-centered">
                  <button
                    type="button"
                    class="btn_small"
                    :disabled="current_page <= 1"
                    @click="current_page = current_page - 1"
                  >
                    ←
                  </button>
                  <small v-if="number_of_possible_pages > 0">
                    {{ `${current_page}/${number_of_possible_pages}` }}
                  </small>

                  <button
                    type="button"
                    class="btn_small"
                    :disabled="current_page >= number_of_possible_pages"
                    @click="current_page = current_page + 1"
                  >
                    →
                  </button>
                </div>
              </template>
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button
                class="modal-default-button btn_small"
                @click="$emit('close')"
              >
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import JSURL from "jsurl";
import * as $ from "jquery";

export default {
  props: {
    values: Object,
    grid_is_enabled: Boolean,
  },
  components: {},
  data() {
    return {
      save_status: false,
      is_loading_patterns: false,

      saved_patterns: [],
      current_page: 1,
      number_of_items_per_page: 17,

      sort_order: "most_recent_first",
    };
  },
  created() {},
  mounted() {
    // const current_pattern = this.$parent.$refs.pattern;

    // let svg_clone = current_pattern.cloneNode(true);
    // svg_clone.setAttribute("version", "1.1");
    // svg_clone.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    // svg_clone.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    // svg_clone.getElementById("shapes").setAttribute("transform", "");

    // svg_clone.getElementById("background_rect").remove();

    // svg_clone.getElementById("grid_x").remove();
    // svg_clone.getElementById("grid_y").remove();

    // var svgData = svg_clone.outerHTML;

    // this.$refs.current_pattern_preview.innerHTML = svgData;

    this.load();

    document.addEventListener("keyup", this.closeOnEscape);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.closeOnEscape);
  },
  watch: {},
  computed: {
    sorted_patterns() {
      const sorted_patterns = this.saved_patterns.slice().sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

      if (this.sort_order === "most_recent_first") {
        sorted_patterns.reverse();
      }

      return sorted_patterns;
    },
    patterns_paginated() {
      if (!this.sorted_patterns) return false;
      return this.sorted_patterns.slice(
        (this.current_page - 1) * this.number_of_items_per_page,
        this.current_page * this.number_of_items_per_page
      );
    },
    number_of_possible_pages() {
      if (!this.sorted_patterns) return false;
      return Math.ceil(
        this.sorted_patterns.length / this.number_of_items_per_page
      );
    },

    pattern_URL() {
      const obj = this.values;
      const str = JSURL.stringify(obj);
      const locationToScene = window.location.href.split("?")[0] + "?" + str;
      return locationToScene;
    },
  },
  methods: {
    closeOnEscape(evt) {
      if (evt.code === "Escape") this.$emit("close");
    },
    humanTime(timestamp) {
      return window.getHumanTime(new Date(timestamp * 1000));
    },
    removePattern(url) {
      url;
    },
    saveSVG() {
      const name = `generateur_graphique-${window.getFormattedTime()}.svg`;

      const svgBlob = this.getSVGBlob({ with_palette: true });
      var svgUrl = URL.createObjectURL(svgBlob);

      var downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    getSVGBlob({ with_palette }) {
      return window.makeBlobFromSVG({
        svgEl: this.$parent.$refs.pattern,
        urlToPattern: this.pattern_URL,
        grid_is_ensabled: this.grid_is_enabled,
        with_palette,
      });
    },

    getBitmapFromSVGBlob() {
      return new Promise((resolve) => {
        const svgBlob = this.getSVGBlob({ with_palette: false });
        var svgUrl = URL.createObjectURL(svgBlob);

        let image = new Image();
        image.onload = () => {
          let canvas = document.createElement("canvas");
          let { width, height } = this.$parent.$refs.pattern.getBBox();
          canvas.width = width;
          canvas.height = height;
          let context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob((blob) => resolve(blob), "image/png");
        };
        image.src = svgUrl;
      });
    },

    save() {
      const filename = `generateur_graphique-${window.getFormattedTime()}.jpg`;
      this.getBitmapFromSVGBlob().then((previewBlob) => {
        const formData = new FormData();
        formData.append("file", previewBlob, filename);
        formData.append("pattern_url", this.pattern_URL);

        this.save_status = "saving";

        // svg + date
        $.ajax({
          url: this.$root.homeURL + "/sauvegardes/save",
          type: "POST",
          data: formData,
          enctype: "multipart/form-data",
          processData: false, // tell jQuery not to process the data
          contentType: false, // tell jQuery not to set contentType
        })
          .done((data) => {
            console.log(`SaveLoadModal / save: done with ${data}`);
            this.save_status = "saved";

            this.load();
            // setTimeout(() => {
            //   this.save_status = false;
            // }, 2000);
          })
          .fail((jqXHR, textStatus) => {
            this.save_status = "failed : " + textStatus;
            setTimeout(() => {
              this.save_status = false;
            }, 2000);
          });
      });
    },
    load() {
      // depuis /sauvegardes/load
      this.is_loading_patterns = true;
      fetch(this.$root.homeURL + "/sauvegardes")
        .then((response) => response.json())
        .then((json) => {
          console.log(`SaveLoadModal / mounted: gotten images`);
          console.log(`${JSON.stringify(json, null, 4)}`);
          this.saved_patterns = json;
          setTimeout(() => {
            this.is_loading_patterns = false;
          }, 500);
        })
        .catch(function (ex) {
          console.log("parsing failed", ex);
          setTimeout(() => {
            this.is_loading_patterns = false;
          }, 500);
        });
    },
  },
};
</script>
<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  overflow-y: auto;
  transition: opacity 0.2s ease;
}

.modal-wrapper {
  // display: table-cell;
  // vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;
}

.modal-container {
  max-width: 800px;
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  // font-family: Helvetica, Arial, sans-serif;

  a {
    word-break: break-all;
  }
}

.modal-header {
  font-weight: bold;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.04);
}

._buttonRow {
  background-color: #eee;
  margin-bottom: 1rem;
  text-align: left;
  padding: 2px;

  label {
    text-transform: uppercase;
    font-size: 0.7em;
  }
}
._buttonRowWrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

._buttonRow--preview {
  // flex: 0 0 200px;
  svg {
    width: 200px;
    height: 200px;
  }
}

._buttonRow--pu {
  margin: 0.5rem;
  margin-top: 0;
  padding: 0.2rem;
}

._patterns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-auto-rows: max-content;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;

  background-color: #eee;

  // grid-auto-rows: 200px;
  padding: 2px;
  grid-gap: 2px;

  > * {
    background-color: #fff;
    padding-bottom: 5px;
    // border: 1px solid black;
    text-align: center;

    > * {
      margin: 0 5px;
    }
  }

  hr {
    margin: 0 5px;
  }
}

._patterns--saveButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

._pattern--timestamp {
  font-size: 50%;
  font-weight: 500;

  // border-bottom: 1px solid #ddd;
}

._pattern--visuel {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  &::before {
    content: "";
    width: 100%;
    padding-bottom: 100%;
    display: block;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: contain;
    object-position: center;
    margin: 5px;
    margin-bottom: 0px;
    border: 1px solid #ddd;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

._pattern--links {
  a {
    color: inherit;
  }
}
</style>
