<template>
  <div class="flex-sidebyside" v-if="access">
    <div class="m_controller">
      <div class="m_controller--topBar">
        <div class="padding-vert-medium border-bottom">
          <button type="button" @click="reloadPage">
            <h1 class="margin-none padding-sides-medium">
              Générateur graphique
            </h1>
            <span class="margin-none padding-sides-medium">Studio ERB</span>
          </button>
        </div>

        <div class="mode_switcher border-bottom">
          <label for="image" class="padding-vert-small padding-sides-medium">
            <input
              type="radio"
              id="image"
              value="Image"
              v-model="current_options_mode"
            />
            <span>1. Settings</span>
          </label>
          <label for="motif" class="padding-small padding-sides-medium">
            <input
              type="radio"
              id="motif"
              value="Motif"
              v-model="current_options_mode"
            />
            <span>2. Design</span>
          </label>
        </div>
      </div>

      <div class="m_controller--content">
        <transition name="flipfront_left">
          <div
            class="panel panel_image padding-vert-small"
            v-show="current_options_mode === 'Image'"
          >
            <Card :type="'section_separator'">
              <div slot="header">Size</div>
            </Card>

            <Card
              :variable="options.motif.size.width"
              v-model="values.motif.size.width"
            >
            </Card>

            <Card
              :variable="options.motif.size.height"
              v-model="values.motif.size.height"
            >
            </Card>

            <Card
              :variable="options.motif.size.offset"
              v-model="values.motif.size.offset"
            >
            </Card>

            <Card :type="'section_separator'">
              <div slot="header">Reference pictures</div>
            </Card>

            <ImageCollection
              :reference_image_name.sync="values.image.reference_image_name"
              :content_page="url_to_images"
            >
            </ImageCollection>

            <div class="margin-sides-medium">
              <button
                type="button"
                class="btn_small"
                @click="image_instructions = !image_instructions"
              >
                Instructions
              </button>
              <div v-if="image_instructions">
                <small>
                  To remove images, connect to
                  <a
                    :href="url_to_panel"
                    target="_blank"
                    v-html="'the panel'"
                  />
                  with login <em>editeur</em> and password <em>erb</em> and edit
                  the page 'Images'.
                </small>
                <br />
                <small>
                  To import new images, convert them to JPEG with 4032 pixels as
                  their longest dimension (width or height). They must be less
                  than 10MB.
                </small>
              </div>
            </div>

            <ImageInput
              :imageRef="values.image.reference_image_name"
              :ratio="pattern_ratio"
              :crop_value.sync="values.image.crop"
              :xcount="values.motif.xcount"
              :ycount="values.motif.ycount"
              :saturation="values.image.saturation"
              :contrast="values.image.contrast"
              :luminosity="values.image.luminosity"
              :invert="values.image.invert"
              :blur="values.image.blur"
              :color_filter="values.image.color_filter"
              :autoUpdate="auto_update"
            >
            </ImageInput>

            <Card :type="'section_separator'">
              <div slot="header">Filters</div>
            </Card>

            <Card
              :variable="options.image.saturation"
              v-model="values.image.saturation"
            >
            </Card>

            <Card
              :variable="options.image.contrast"
              v-model="values.image.contrast"
            >
            </Card>

            <Card
              :variable="options.image.luminosity"
              v-model="values.image.luminosity"
            >
            </Card>

            <Card
              :variable="options.image.invert"
              v-model="values.image.invert"
            >
            </Card>

            <Card :variable="options.image.blur" v-model="values.image.blur">
            </Card>

            <Card
              :variable="options.image.color_filter.enabled"
              v-model="values.image.color_filter.enabled"
            >
            </Card>
            <Card
              v-show="values.image.color_filter.enabled"
              :variable="options.image.color_filter.color"
              v-model="values.image.color_filter.color"
            >
            </Card>
            <Card
              v-show="values.image.color_filter.enabled"
              :variable="options.image.color_filter.density"
              v-model="values.image.color_filter.density"
            >
            </Card>
          </div>
        </transition>

        <transition name="flipfront_right">
          <div
            class="panel panel_pattern padding-vert-small"
            v-show="current_options_mode === 'Motif'"
          >
            <Card :type="'section_separator'">
              <div slot="header">Density</div>
            </Card>

            <Card
              :variable="options.motif.xcount"
              v-model="values.motif.xcount"
            />
            <Card
              :variable="options.motif.ycount"
              v-model="values.motif.ycount"
            />
            <Card
              :variable="options.motif.random_position"
              v-model="values.motif.random_position"
            />
            <Card
              :variable="options.motif.random_position_x"
              v-model="values.motif.random_position_x"
            />
            <Card
              :variable="options.motif.random_position_y"
              v-model="values.motif.random_position_y"
            />
            <Card
              :variable="options.motif.drawing_order"
              v-model="values.motif.drawing_order"
            />
            <Card
              :variable="options.motif.drawing_order_random"
              v-model="values.motif.drawing_order_random"
            />

            <Card :type="'section_separator'">
              <div slot="header">Type</div>
            </Card>

            <Card :variable="options.motif.type" v-model="values.motif.type" />

            <!-- <Card 
              v-if="values.motif.type === 'custom'"
              :variable="options.motif.custom_path"
              v-model="values.motif.custom_path"
            >
            </Card> -->

            <ImageCollection
              v-if="values.motif.type === 'custom'"
              :reference_image_name.sync="values.motif.reference_svg_name"
              :content_page="url_to_svgs"
            />
            <div
              class="margin-sides-medium"
              v-if="values.motif.type === 'custom'"
            >
              <button
                type="button"
                class="btn_small"
                @click="svg_instructions = !svg_instructions"
              >
                Instructions
              </button>
              <div v-if="svg_instructions">
                <small>
                  To remove SVGs, connect to
                  <a
                    :href="url_to_panel"
                    target="_blank"
                    v-html="'the panel'"
                  />
                  with login <em>editeur</em> and password <em>erb</em>, and
                  edit the page 'SVG'.
                </small>
                <br />
                <small>
                  To import a new SVG image, use a graphics software such as
                  Adobe Illustrator or Inkscape. Only use solid shapes (no
                  opacity) on an artboard that is a few pixels wide and high.
                  <a
                    download="example.svg"
                    :href="template_svg_link"
                    v-html="'example.svg'"
                  />
                </small>
              </div>
            </div>

            <template
              v-if="
                values.motif.type !== 'circle' && values.motif.type !== 'square'
              "
            >
              <Card :type="'section_separator'">
                <div slot="header">
                  <template
                    v-if="
                      values.motif.type === 'line' ||
                      values.motif.type === 'rect'
                    "
                  >
                    Length
                  </template>
                  <template v-else> Width </template>
                </div>
              </Card>

              <Card
                :variable="options.motif.width"
                v-model="values.motif.width"
              />
              <Card
                :variable="options.motif.mappings.width.source"
                v-model="values.motif.mappings.width.source"
              />

              <Card
                :type="'child_component'"
                v-show="!!values.motif.mappings.width.source"
                :variable="options.motif.mappings.width.min"
                v-model="values.motif.mappings.width.min"
              />
              <Card
                :type="'child_component'"
                v-show="!!values.motif.mappings.width.source"
                :variable="options.motif.mappings.width.max"
                v-model="values.motif.mappings.width.max"
              />
            </template>

            <Card :type="'section_separator'">
              <div slot="header">
                <template v-if="values.motif.type === 'square'">
                  Width and height
                </template>
                <template v-else-if="values.motif.type === 'circle'">
                  Diameter
                </template>
                <template
                  v-else-if="
                    values.motif.type === 'line' || values.motif.type === 'rect'
                  "
                >
                  Thickness
                </template>
                <template v-else> Height </template>
              </div>
            </Card>

            <Card
              :variable="options.motif.height"
              v-model="values.motif.height"
            />
            <Card
              :variable="options.motif.mappings.height.source"
              v-model="values.motif.mappings.height.source"
            />
            <Card
              :type="'child_component'"
              v-show="!!values.motif.mappings.height.source"
              :variable="options.motif.mappings.height.min"
              v-model="values.motif.mappings.height.min"
            />
            <Card
              :type="'child_component'"
              v-show="!!values.motif.mappings.height.source"
              :variable="options.motif.mappings.height.max"
              v-model="values.motif.mappings.height.max"
            />

            <template v-if="values.motif.type !== 'circle'">
              <Card :type="'section_separator'">
                <div slot="header">Angle</div>
              </Card>

              <Card
                :variable="options.motif.angle"
                v-model="values.motif.angle"
              />
              <Card
                :variable="options.motif.random_angle"
                v-model="values.motif.random_angle"
              />

              <Card
                :variable="options.motif.mappings.angle.source"
                v-model="values.motif.mappings.angle.source"
              />

              <Card
                :type="'child_component'"
                v-show="!!values.motif.mappings.angle.source"
                :variable="options.motif.mappings.angle.min"
                v-model="values.motif.mappings.angle.min"
              />
              <Card
                :type="'child_component'"
                v-show="!!values.motif.mappings.angle.source"
                :variable="options.motif.mappings.angle.max"
                v-model="values.motif.mappings.angle.max"
              />
            </template>

            <Card :type="'section_separator'">
              <div slot="header">Colour</div>
            </Card>
            <Card
              :variable="options.motif.mappings.fill.source"
              v-model="values.motif.mappings.fill.source"
            />

            <Card
              :type="'child_component'"
              v-show="values.motif.mappings.fill.source === ''"
              :variable="options.motif.fill"
              v-model="values.motif.fill"
            />

            <Card
              :type="'child_component'"
              v-show="values.motif.mappings.fill.source === 'quantization'"
              :variable="options.motif.mappings.fill.number_of_colors"
              v-model="values.motif.mappings.fill.number_of_colors"
            />
          </div>
        </transition>
      </div>

      <div class="m_controller--bottomBar border-top padding-medium">
        <button
          type="button"
          @click="updatePattern()"
          @keyup.enter="updatePattern()"
          title="Raccourci : touche entrée"
        >
          <!-- disabled because of randoms, to allow for regeneration -->
          <!-- :class="{ disabled: !suggest_update }" -->
          Motif update
        </button>
        <button
          class="btn_small"
          :disabled="previous_values === false"
          @click="undoLastValuesChange"
        >
          undo last change
        </button>
        <label class="t-small">
          <input type="checkbox" v-model="auto_update" />
          <span> automatic update </span>
        </label>
        <hr class="margin-vert-small" />
        <div class="m_controller--bottomBar--credits t-small">
          <small>
            creation:
            <a href="http://www.bouroullec.com/" target="_blank"
              >Ronan & Erwan Bouroullec</a
            >
            <br />
            user interface and code:
            <a href="https://louiseveillard.com" target="_blank">
              Louis Eveillard
            </a>
          </small>
        </div>
      </div>
    </div>

    <div class="m_pattern">
      <PatternSvg
        :width="values.motif.size.width"
        :height="values.motif.size.height"
        :offset="values.motif.size.offset"
        :shapes="shapes_props"
        :shapes_palette="shapes_palette"
        :url_to_svg="values.motif.reference_svg_name"
        :values="values"
      >
      </PatternSvg>
    </div>
  </div>
</template>
<script>
import Card from "./components/Card.vue";
import ImageInput from "./components/ImageInput.vue";
import ImageCollection from "./components/ImageCollection.vue";
import PatternSvg from "./components/PatternSvg.vue";

import color from "color";
import map from "range-map";
import rgbquant from "rgbquant";
import JSURL from "jsurl";

export default {
  props: {},
  components: {
    Card,
    PatternSvg,
    ImageInput,
    ImageCollection,
  },
  data() {
    return {
      shapes_props: [],
      shapes_palette: [],
      current_options_mode: "Image",
      access: false,
      has_booted: false,
      image_instructions: false,
      svg_instructions: false,

      suggest_update: false,
      auto_update: true,

      current_values: {},
      previous_values: false,

      values: {
        image: {
          reference_image_name: undefined,
          crop: {
            x: undefined,
            y: undefined,
            width: undefined,
            height: undefined,
            enabled: undefined,
            homotethie: undefined,
          },
          saturation: undefined,
          contrast: undefined,
          luminosity: undefined,
          invert: undefined,
          blur: undefined,
          color_filter: {
            enabled: undefined,
            color: undefined,
            density: undefined,
          },
        },
        motif: {
          type: undefined,
          reference_svg_name: undefined,
          size: {
            width: undefined,
            height: undefined,
            offset: undefined,
          },
          xcount: undefined,
          ycount: undefined,
          random_position: undefined,
          random_position_x: undefined,
          random_position_y: undefined,
          drawing_order: undefined,
          drawing_order_random: undefined,
          width: undefined,
          height: undefined,
          fill: undefined,
          angle: undefined,
          random_angle: undefined,
          mappings: {
            width: {
              source: undefined,
              min: undefined,
              max: undefined,
            },
            height: {
              source: undefined,
              min: undefined,
              max: undefined,
            },
            angle: {
              source: "luminosity",
              min: 2,
              max: 120,
            },
            fill: {
              source: "quantization",
              number_of_colors: undefined,
            },
          },
        },
      },

      options: {
        image: {
          saturation: {
            label: "Saturation",
            default: 0,
            min: -100,
            max: 100,
            field_type: "slider",
          },
          contrast: {
            label: "Contrast",
            default: 0,
            min: -100,
            max: 100,
            field_type: "slider",
          },
          luminosity: {
            label: "Brightness",
            default: 0,
            min: -100,
            max: 100,
            field_type: "slider",
          },
          invert: {
            label: "Negative",
            default: false,
            field_type: "toggle",
          },
          blur: {
            label: "Blur",
            default: 0,
            min: 0,
            max: 10,
            step: 0.1,
            field_type: "slider",
          },
          color_filter: {
            enabled: {
              label: "Coloured filter",
              default: false,
              field_type: "toggle",
            },
            color: {
              label: "Colour",
              field_type: "color",
              default: "#09606F",
            },
            density: {
              label: "Density",
              default: 50,
              min: 0,
              max: 100,
              field_type: "slider",
            },
          },
        },
        motif: {
          type: {
            label: "Select shape",
            default: "line",
            field_type: "select",
            field_options: [
              {
                key: "line",
                name: "line",
              },
              {
                key: "rect",
                name: "rectangle",
              },
              {
                key: "circle",
                name: "circle",
              },
              {
                key: "square",
                name: "square",
              },
              {
                key: "custom",
                name: "custom svg",
              },
            ],
          },
          // custom_path: {
          //   label: 'Code SVG',
          //   default: ``,
          //   field_type: 'textarea'
          // },
          size: {
            label: "Size",
            width: {
              label: "Motif width",
              min: 0,
              max: 5000,
              default: 1500,
              field_type: "slider",
            },
            height: {
              label: "Motif height",
              min: 0,
              max: 5000,
              default: 2000,
              field_type: "slider",
            },
            offset: {
              label: "Offset",
              min: -1000,
              max: 1000,
              default: 0,
              field_type: "slider",
            },
          },
          xcount: {
            label: "Items per lines ↔",
            min: 1,
            max: 1000,
            default: 100,
            field_type: "slider",
          },
          ycount: {
            label: "Items per columns ↕",
            min: 1,
            max: 1000,
            default: 100,
            field_type: "slider",
          },
          random_position: {
            label: "Randomness (x and y)",
            min: 0,
            max: 100,
            default: 0,
            step: 0.1,
            field_type: "slider",
          },
          random_position_x: {
            label: "Randomness x",
            min: 0,
            max: 100,
            default: 0,
            step: 0.1,
            field_type: "slider",
          },
          random_position_y: {
            label: "Randomness y",
            min: 0,
            max: 100,
            default: 0,
            step: 0.1,
            field_type: "slider",
          },
          drawing_order: {
            label: "Drawing order",
            field_type: "select",
            default: "",
            field_options: [
              {
                key: "",
                name: "topleft",
              },
              {
                key: "lightest",
                name: "lightest in front",
              },
              {
                key: "darkest",
                name: "darkest in front",
              },
              {
                key: "most_saturated",
                name: "most saturated in front",
              },
              {
                key: "less_saturated",
                name: "less saturated in front",
              },
            ],
          },
          drawing_order_random: {
            label: "Drawing order random %",
            min: 0,
            max: 100,
            step: 0.1,
            default: 10,
            field_type: "slider",
          },
          width: {
            label: "Base",
            min: 0,
            max: 2000,
            step: 0.1,
            default: 200,
            field_type: "slider",
          },
          height: {
            label: "Base",
            min: 0,
            max: 500,
            step: 0.1,
            default: 2,
            field_type: "slider",
          },
          fill: {
            label: "Fill",
            field_type: "color",
            default: "#7DC1C8",
          },
          angle: {
            label: "Base ⤵",
            min: 0,
            max: 360,
            default: 50,
            field_type: "slider",
          },
          random_angle: {
            label: "Randomness ⤹⤵",
            min: 0,
            max: 180,
            default: 0,
            field_type: "slider",
          },
          mappings: {
            width: {
              source: {
                label: "Modulate length with",
                default: "",
                field_type: "select",
                field_options: [
                  {
                    key: "",
                    name: "—",
                  },
                  {
                    key: "luminosity",
                    name: "the brightness",
                  },
                  {
                    key: "saturation",
                    name: "the saturation",
                  },
                ],
              },
              min: {
                label: "When value equals zero, increase the items’ width by",
                min: 0,
                max: 100,
                default: 2,
                field_type: "slider",
              },
              max: {
                label: "When value is at maximum, increase the items’ width by",
                min: 0,
                max: 100,
                default: 20,
                field_type: "slider",
              },
            },
            height: {
              source: {
                label: "Modulate thickness with",
                default: "",
                field_type: "select",
                field_options: [
                  {
                    key: "",
                    name: "—",
                  },
                  {
                    key: "luminosity",
                    name: "the brightness",
                  },
                  {
                    key: "saturation",
                    name: "the saturation",
                  },
                ],
              },
              min: {
                label:
                  "When value equals zero, increase the items’ thickness by",
                min: 0,
                max: 100,
                default: 2,
                field_type: "slider",
              },
              max: {
                label:
                  "When value is at maximum, increase the items’ thickness by",
                min: 0,
                max: 100,
                default: 5,
                field_type: "slider",
              },
            },
            angle: {
              source: {
                label: "Modulate angle with",
                default: "",
                field_type: "select",
                field_options: [
                  {
                    key: "",
                    name: "—",
                  },
                  {
                    key: "luminosity",
                    name: "the brightness",
                  },
                  {
                    key: "saturation",
                    name: "the saturation",
                  },
                ],
              },
              min: {
                label: "When value equals zero, increase the items’ angle by",
                min: 0,
                max: 360,
                default: 10,
                field_type: "slider",
              },
              max: {
                label: "When value is at maximum, increase the items’ angle by",
                min: 0,
                max: 360,
                default: 200,
                field_type: "slider",
              },
            },
            fill: {
              source: {
                label: "Modulate colour with",
                default: "raw",
                field_type: "select",
                field_options: [
                  {
                    key: "",
                    name: "a unique colour",
                  },
                  {
                    key: "raw",
                    name: "picture’s colours",
                  },
                  {
                    key: "quantization",
                    name: "a limited palette (experimental)",
                  },
                ],
              },
              number_of_colors: {
                label: "Number of colours in the palette",
                min: 2,
                max: 128,
                default: 8,
                field_type: "slider",
              },
            },
          },
        },
      },
    };
  },
  created() {
    this.loadSceneInUrl();
  },
  mounted() {
    this.$eventHub.$on(
      "updatedPixelAttributes",
      this.rebuildShapesAttrFromPixels
    );
    this.$eventHub.$on("suggestUpdates", () => {
      this.suggest_update = true;
    });
    this.$eventHub.$on("updateCrop", this.updateCropValues);
    document.addEventListener("keyup", this.updateOnEnter);

    // Create anchor element and use href property for the purpose of this example
    // A more correct alternative is to browse to the URL and use document.location or window.location
    let url = document.createElement("a");
    url.href = this.values.image.reference_image_name;
    // console.log(url.hostname);  // developer.mozilla.org
    // console.log(url.pathname);  // /en-US/search
    // console.log(url.origin);    // https://developer.mozilla.org

    if (url.hostname !== location.hostname) {
      console.log("Correction URL to wrong domain");
      const img = this.values.image.reference_image_name;
      // récupérer la partie qui commence à /content
      // const pathToImage = img.substr(img.indexOf('content/'));
      // mettre devant location.origin + location.pathname
      // this.values.image.reference_image_name = location.origin + location.pathname + pathToImage;
      this.values.image.reference_image_name = img.replace(
        url.hostname,
        location.hostname
      );
    }

    // check if chrome
    function isChrome() {
      var isChromium = window.chrome,
        winNav = window.navigator,
        vendorName = winNav.vendor,
        isOpera = winNav.userAgent.indexOf("OPR") > -1,
        isIEedge = winNav.userAgent.indexOf("Edge") > -1,
        isIOSChrome = winNav.userAgent.match("CriOS");

      if (isIOSChrome) {
        return false;
      } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (!isChrome()) {
      window.alert(
        "This application was created and optimized for GOOGLE CHROME on computer. In order to avoid bugs, please use this web-browser."
      );
    }

    if (!this.access) {
      let hashCode = function (s) {
        return s.split("").reduce(function (a, b) {
          a = (a << 5) - a + b.charCodeAt(0);
          return a & a;
        }, 0);
      };

      if (
        localStorage.getItem("pass") &&
        hashCode(localStorage.getItem("pass")) === 100693
      ) {
        this.access = true;
      } else {
        let mdp = prompt("Password for application :");

        if (hashCode(mdp) !== 100693) {
          window.alert("This password is not valid.");
          throw "Error";
        } else {
          this.access = true;
          localStorage.setItem("pass", mdp);
        }
      }
    }

    this.has_booted = true;
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.updatePattern();
      });
    });
  },
  beforeDestroy() {
    this.$eventHub.$off(
      "updatedPixelAttributes",
      this.rebuildShapesAttrFromPixels
    );
    this.$eventHub.$off("suggestUpdates");
    this.$eventHub.$off("updateCrop", this.updateCropValues);
    document.removeEventListener("keyup", this.updateOnEnter);
  },

  watch: {
    values: {
      handler: function () {
        console.log(`App / watch: values`);
        this.suggest_update = true;

        this.previous_values = JSON.parse(JSON.stringify(this.current_values));
        this.current_values = JSON.parse(JSON.stringify(this.values));
      },
      deep: true,
    },

    auto_update: function () {
      console.log(`App / watch: auto_update`);
      if (this.suggest_update && this.auto_update) {
        console.log(
          `App / watch: auto_update — updating automatically pattern`
        );
        this.$nextTick(() => this.updatePattern());
      }
    },

    suggest_update: function () {
      console.log(`App / watch: suggest_update`);
      if (this.suggest_update && this.auto_update) {
        console.log(
          `App / watch: suggest_update — updating automatically pattern`
        );
        this.$nextTick(() => this.updatePattern());
      }
    },
  },
  computed: {
    template_svg_link() {
      return this.$root.homeURL + "/svg/2018-08-05_18-37-30_svg.svg";
    },
    homeURL() {
      return this.$root.homeURL;
    },
    url_to_panel() {
      return this.$root.homeURL + "/panel";
    },
    url_to_images() {
      return this.$root.homeURL + "/images";
    },
    url_to_svgs() {
      return this.$root.homeURL + "/svg";
    },
    allOptions() {
      return Object.keys(this.options).map((val) => {
        return this.options[val];
      });
    },
    pattern_ratio() {
      return this.values.motif.size.width / this.values.motif.size.height;
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    updateCropValues(crop) {
      console.log(`App / methods: updateCropValues`);
      this.values.image.crop = crop;
    },
    loadSceneInUrl() {
      // find ? in beginning
      if (location.search.length === 0) return false;

      let params = location.search.substr(1);
      let sceneData = JSURL.tryParse(params, false);

      if (sceneData === false) {
        // because Facebook
        sceneData = JSURL.tryParse(decodeURI(params), false);
        if (sceneData === false) {
          return false;
        }
      }

      Object.keys(this.values).forEach((t) => {
        Object.keys(this.values[t]).forEach((k) => {
          if (Object.prototype.hasOwnProperty.call(sceneData[t], k)) {
            this.values[t][k] = sceneData[t][k];
          }
        });
      });

      // this.values = sceneData;
    },
    updateOnEnter() {
      if (event.key === "Enter") {
        this.updatePattern();
      }
    },
    undoLastValuesChange() {
      console.log(`App / methods: undoLastValuesChange`);
      this.values = JSON.parse(JSON.stringify(this.previous_values));
    },
    make_shapes(pixel_array) {
      console.log(`App / methods: make_shapes`);
      var t0 = performance.now();

      let xcount = this.values.motif.xcount;
      let ycount = this.values.motif.ycount;
      let random_position = this.values.motif.random_position;
      let random_position_x = this.values.motif.random_position_x;
      let random_position_y = this.values.motif.random_position_y;
      let drawing_order = this.values.motif.drawing_order;
      let drawing_order_random = this.values.motif.drawing_order_random;

      let x_step = this.values.motif.size.width / xcount;
      let y_step = this.values.motif.size.height / ycount;

      if (pixel_array.length === 0) {
        return [];
      }

      let base_width = this.values.motif.width;
      let base_height = this.values.motif.height;
      let base_angle = this.values.motif.angle;
      let base_random_angle = this.values.motif.random_angle;
      let base_fill = this.values.motif.fill;
      let base_type = this.values.motif.type;

      // if this.values.motif.mappings.source === 'quantization' alors calculer la version réduit de l’image
      let sampled_pixel_array = [];
      if (this.values.motif.mappings.fill.source === "quantization") {
        var opts = {
          colors: this.values.motif.mappings.fill.number_of_colors, // desired palette size
          method: 1, // histogram method, 2: min-population threshold within subregions; 1: global top-population
          boxSize: [32, 32], // subregion dims (if method = 2)
          boxPxls: 2, // min-population threshold (if method = 2)
          initColors: 4096, // # of top-occurring colors  to start with (if method = 1)
          minHueCols: 0, // # of colors per hue group to evaluate regardless of counts, to retain low-count hues
          dithKern: null, // dithering kernel name, see available kernels in docs below
          dithDelta: 0, // dithering threshhold (0-1) e.g: 0.05 will not dither colors with <= 5% difference
          dithSerp: false, // enable serpentine pattern dithering
          palette: [], // a predefined palette to start with in r,g,b tuple format: [[r,g,b],[r,g,b]...]
          reIndex: false, // affects predefined palettes only. if true, allows compacting of sparsed palette once target palette size is reached. also enables palette sorting.
          useCache: true, // enables caching for perf usually, but can reduce perf in some cases, like pre-def palettes
          cacheFreq: 10, // min color occurance count needed to qualify for caching
          colorDist: "euclidean", // method used to determine color distance, can also be "manhattan"
        };
        let q = new rgbquant(opts);
        q.sample(pixel_array, xcount);
        let pal = q.palette(true);
        sampled_pixel_array = q.reduce(pixel_array);
        this.shapes_palette = pal;
      } else {
        this.shapes_palette = [];
      }

      let shapes = [];

      for (var i = 0; i < pixel_array.length; i += 4) {
        let index = i / 4;
        let localColor = color.rgb([
          pixel_array[i],
          pixel_array[i + 1],
          pixel_array[i + 2],
        ]);

        let posX = (index % xcount) * x_step + x_step / 2;
        let posY = Math.floor(index / xcount) * y_step + y_step / 2;

        posX += Math.random() * random_position - random_position / 2;
        posY += Math.random() * random_position - random_position / 2;

        posX += Math.random() * random_position_x - random_position_x / 2;
        posY += Math.random() * random_position_y - random_position_y / 2;

        let width = base_width;
        let height = base_height;
        let angle = base_angle;
        let random_angle = base_random_angle;
        if (random_angle) {
          random_angle *= 1;
          angle += Math.random() * random_angle - random_angle / 2;
        }
        let fill = base_fill;
        let type = base_type;

        let pixel_attr_obj = {
          posX,
          posY,
          width,
          height,
          angle,
          fill,
          type,
        };

        // mappings / modifiers connection various dimensions between them
        let mappings = this.values.motif.mappings;

        Object.keys(mappings).map(function (type) {
          if (
            mappings[type].source !== undefined &&
            mappings[type].source !== ""
          ) {
            if (mappings[type].source === "luminosity") {
              if (
                mappings[type].min === undefined ||
                mappings[type].max === undefined
              ) {
                return;
              }
              pixel_attr_obj[type] += map(
                localColor.luminosity(),
                0,
                1,
                mappings[type].min,
                mappings[type].max
              );
            } else if (mappings[type].source === "saturation") {
              if (
                mappings[type].min === undefined ||
                mappings[type].max === undefined
              ) {
                return;
              }
              pixel_attr_obj[type] += map(
                localColor.saturationv(),
                0,
                100,
                mappings[type].min,
                mappings[type].max
              );
            } else if (mappings[type].source === "raw") {
              pixel_attr_obj[type] = localColor.string();
            } else if (mappings[type].source === "quantization") {
              if (sampled_pixel_array.length > 0) {
                let sampledPixelColor = color.rgb([
                  sampled_pixel_array[i],
                  sampled_pixel_array[i + 1],
                  sampled_pixel_array[i + 2],
                ]);
                pixel_attr_obj[type] = sampledPixelColor.string();
              }
            }
          }
        });

        let hasNonvalidValues = Object.values(pixel_attr_obj).some((val) => {
          if (typeof val === "number" && !Number.isFinite(val)) {
            return true;
          }
          if (typeof val === "string" && val === "") {
            return true;
          }
          return false;
        });
        if (hasNonvalidValues) {
          console.error(
            `App / make_shapes — couldn’t build shapes array with`,
            pixel_attr_obj
          );
          shapes = [];
          break;
        }

        // check to remove unused items
        if (
          pixel_attr_obj !== ""
          // && pixel_attr_obj.fill !== 'rgb(255, 255, 255)'
          // && pixel_attr_obj.width > 0
          // && pixel_attr_obj.height > 0
        ) {
          shapes.push(pixel_attr_obj);
        }
      }

      if (drawing_order === "random") {
        //Fisher-Yatess
        // disabled after Erwan’s feedbacks
        for (let index = shapes.length - 1; index > 0; index--) {
          const newIndex = Math.floor(Math.random() * (index + 1));
          [shapes[index], shapes[newIndex]] = [shapes[newIndex], shapes[index]];
        }
      } else if (drawing_order === "darkest") {
        shapes.sort((a, b) => {
          let lumA = color(a.fill).luminosity();
          let lumB = color(b.fill).luminosity();
          return lumB - lumA;
        });
      } else if (drawing_order === "lightest") {
        shapes.sort((a, b) => {
          let lumA = color(a.fill).luminosity();
          let lumB = color(b.fill).luminosity();
          return lumA - lumB;
        });
      } else if (drawing_order === "less_saturated") {
        shapes.sort((a, b) => {
          let satA = color(a.fill).saturationv();
          let satB = color(b.fill).saturationv();
          return satB - satA;
        });
        //
      } else if (drawing_order === "most_saturated") {
        shapes.sort((a, b) => {
          let satA = color(a.fill).saturationv();
          let satB = color(b.fill).saturationv();
          return satA - satB;
        });
        //
      }

      if (drawing_order_random > 0) {
        for (let index = shapes.length - 1; index > 0; index--) {
          if (Math.random() > drawing_order_random / 100) continue;
          const newIndex = Math.floor(Math.random() * (index + 1));
          [shapes[index], shapes[newIndex]] = [shapes[newIndex], shapes[index]];
        }
      }

      var t1 = performance.now();
      console.log(
        `App / make_shapes — generation took ${t1 - t0} millisecondes.`
      );

      return shapes;
    },
    updatePattern() {
      console.log(`App / updatePattern`);

      if (!this.has_booted) {
        return false;
      }

      this.$root.is_updating = true;

      // au click du bouton MAJ ou en auto update
      // demander à rescanner l’image de ref filtrée
      this.suggest_update = false;
      this.$eventHub.$emit("scanCanvas");
    },
    rebuildShapesAttrFromPixels(pixelArrayAttr) {
      // appelé par scanCanvas de ImageInput, ou directement pour seulement changer les attributs du motif
      this.$nextTick(() => {
        this.shapes_props = this.make_shapes(pixelArrayAttr);
      });
    },
  },
};
</script>
<style src="./css/shoelace.css"></style>
<style src="./css/vue2-animate.min.css"></style>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "IBM Plex Sans", sans-serif;

  line-height: 1.5;
  background-color: white;
  color: black;
  font-weight: 400;
  letter-spacing: 0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 600;
}

h1,
h2 {
  font-size: 1em;
  line-height: 1.5;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.button.disabled,
.button:disabled,
button.disabled,
button:disabled {
  background-color: #999;
}

.btn_small:hover,
.btn_small:focus {
  background-color: #999;
}

button.active {
  background-color: #0074d9 !important;
  color: white !important;
}

input {
  background-color: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.btn_small {
  background: transparent;
  border: none;
  color: #999;

  text-transform: uppercase;
  font-size: 0.7em;
  padding: 0.2rem;
  margin-bottom: 0;
  line-height: 1;
  min-height: 1.2em;
  height: 2em;

  &:hover,
  &:focus {
    outline: 0;
    color: #fff;
    background-color: #999;
  }
}

.flex-sidebyside {
  display: flex;
  flex-flow: row nowrap;

  > * {
    flex: 1 1 auto;
    height: 100vh;

    &.m_controller {
      flex: 1 0 320px;
    }

    &.m_pattern {
    }
  }
}

.m_controller {
  position: relative;
  z-index: 1;

  left: 0;
  top: 0;
  overflow: hidden;
  border-right: 1px solid #000;

  display: flex;
  flex-flow: column nowrap;

  justify-content: space-between;

  > * {
    flex: 1 1 auto;

    &.m_controller--bottomBar {
      flex: 0 0 auto;
    }
  }
}

.m_controller--content {
  position: relative;

  .panel {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: visible;
    z-index: 1;
    background-color: white;

    width: calc(100% + 2px);
    margin-left: -1px;
    border-left: 1px solid #000;
    border-right: 1px solid #000;

    $scrollbar-height: 3px;
    $scrollbar-padding: 10px;

    &::-webkit-scrollbar {
      height: (($scrollbar-padding * 2) + $scrollbar-height);
      width: (($scrollbar-padding * 2) + $scrollbar-height);
      background-color: rgba(255, 255, 255, 0);
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border: 10px solid rgba(255, 255, 255, 0);
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
      background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #212121;
      &:hover {
        border: $scrollbar-padding solid rgba(255, 255, 255, 0);
      }
    }
  }
}

.m_controller--topBar {
  min-height: 60px;
  flex: 0 0 auto;

  button {
    display: block;
    font-size: inherit;
    appearance: none;
    background: transparent;
    border: none;
    height: auto;
    line-height: inherit;
    text-align: left;
    padding: 0;
    text-decoration: none;
    color: inherit;
  }
}

.m_controller--bottomBar {
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  > * {
    flex: 0 0 auto;
  }

  button {
    // width: 100%;
    // border-radius: 0;
    // height: auto;
    margin-bottom: 10px;
  }
  hr {
    width: 100%;
  }
}

.m_pattern {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-color: #f9f9f9;
  text-align: center;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: "";
    height: 100%;
    width: 1px;
  }

  > *,
  &::after {
    display: inline-block;
    vertical-align: middle;
  }
}

svg {
  background-color: #fff;
  border: 2px solid #d9d9d9;
}

canvas {
  cursor: crosshair;
}

.mode_switcher {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;

  > * {
    flex: 1 1 50%;
    cursor: pointer;
    margin: 0;

    display: flex;
    align-items: center;

    &:nth-child(2) {
      border-left: 1px solid black;
    }

    > span {
      padding-left: 0.5em;
    }
  }
}

.t-ellipse {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.t-small {
  font-size: 0.8em;
}

.margin-none {
  margin: 0;
}
.margin-medium {
  margin: 1rem;
}
.margin-vert-small {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.margin-vert-medium {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.margin-sides-medium {
  margin-left: 1rem;
  margin-right: 1rem;
}

.padding-small {
  padding: 0.5rem;
}
.padding-medium {
  padding: 1rem;
}
.padding-left-medium {
  padding-left: 1rem;
}
.padding-left-large {
  padding-left: 2rem;
}
.padding-right-medium {
  padding-right: 1rem;
}

.padding-vert-medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.padding-vert-small {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.padding-sides-medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.padding-sides-small {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.border-bottom {
  border-bottom: 1px solid black;
}
.border-top {
  border-top: 1px solid black;
}
.padding-bottom-small {
  padding-bottom: 0.5rem;
}
.padding-top-small {
  padding-top: 0.5rem;
}
.margin-top-small {
  margin-top: 0.5rem;
}

.flipfront_left-enter-active,
.flipfront_left-leave-active {
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.flipfront_left-enter-active {
  animation-name: slideleftin;
}
.flipfront_left-leave-active {
  animation-name: slideleftout;
}

.flipfront_right-enter-active,
.flipfront_right-leave-active {
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.flipfront_right-enter-active {
  animation-name: sliderightin;
}
.flipfront_right-leave-active {
  animation-name: sliderightout;
}

@keyframes sliderightout {
  0% {
    transform: translate3d(0, 0, 0);
    z-index: 2;
  }
  50% {
    transform: translate3d(80%, 0, 0);
    z-index: 2;
  }
  100% {
    transform: translate3d(0%, 0, 0);
    z-index: -1;
  }
}
@keyframes sliderightin {
  0% {
    transform: translate3d(0, 0, 0);
    z-index: -1;
  }
  50% {
    transform: translate3d(80%, 0, 0);
    z-index: 2;
  }
  100% {
    transform: translate3d(0%, 0, 0);
    z-index: 2;
  }
}

@keyframes slideleftout {
  0% {
    transform: translate3d(0, 0, 0);
    z-index: 2;
  }
  50% {
    transform: translate3d(-80%, 0, 0);
    z-index: 2;
  }
  100% {
    transform: translate3d(0%, 0, 0);
    z-index: -1;
  }
}

@keyframes slideleftin {
  0% {
    transform: translate3d(0, 0, 0);
    z-index: -1;
  }
  50% {
    transform: translate3d(-60%, 0, 0);
    z-index: 2;
  }
  100% {
    transform: translate3d(0%, 0, 0);
    z-index: 2;
  }
}

.zoom {
  fill: none;
  pointer-events: all;
}

.loader {
  border: 0.2rem solid #fff;
  border-top-color: #000;
  border-left-color: #000;
}

.list-complete-move {
  position: relative;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1) !important;
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}
.list-complete-leave-active {
  position: absolute;
  z-index: 0 !important;
}
</style>
