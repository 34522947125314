<template>
  <Card>
    <div slot="body" class="m_imageCollection">
      <div
        class="m_imageCollection--vignette m_imageCollection--vignette_import"
      >
        <input
          v-if="import_button_label === ''"
          type="file"
          class="inputfile"
          name="myImage"
          :id="`myImage-${random_id}`"
          accept="image/*"
          @change="uploadImage($event)"
        />
        <label :for="`myImage-${random_id}`" class="btn_small">
          {{ import_button_label === "" ? "Import" : import_button_label }}
        </label>
      </div>
      <template v-for="image in image_collection">
        <div class="m_imageCollection--vignette" :key="image.filename">
          <input
            type="radio"
            :id="image.filename"
            :value="image.url"
            v-model="local_selected_image_name"
          />
          <label :for="image.filename" :title="image.filename">
            <img :src="image.thumb" />
          </label>
        </div>
      </template>
    </div>
  </Card>
</template>
<script>
import Card from "./Card.vue";
import * as $ from "jquery";

export default {
  components: {
    Card,
  },
  props: {
    reference_image_name: {
      type: String,
      default: "",
    },
    content_page: {
      type: String,
    },
  },
  data() {
    return {
      image_collection: [],
      local_selected_image_name: this.reference_image_name,
      import_button_label: "",
      uploadURL: this.content_page + "/upload",
      random_id: (Math.random().toString(36) + "00000000000000000").slice(
        2,
        3 + 2
      ),
    };
  },

  created() {
    // list all images on the server
  },
  mounted() {
    // request all images
    console.log(`ImageCollection / mounted: requesting images`);
    this.getAllImages();
  },
  beforeDestroy() {},

  watch: {
    local_selected_image_name: function () {
      this.$emit("update:reference_image_name", this.local_selected_image_name);
    },
  },
  computed: {},
  methods: {
    uploadImage(event) {
      let formData = new FormData();

      const img = event.target.files[0];
      const filesize = (img.size / 1024 / 1024).toFixed(4); // MB

      if (filesize > 10) {
        window.alert(
          `Image too heavy (> 10MB), upload is forbidden. Please resize it according to the instructions.`
        );
      } else {
        formData.append("file", img);
        console.log(
          `ImageCollection / uploadImage: uploading formData to ${this.uploadURL}`
        );
        this.import_button_label = "Uploading…";
        $.ajax({
          url: this.uploadURL,
          type: "POST",
          data: formData,
          enctype: "multipart/form-data",
          processData: false, // tell jQuery not to process the data
          contentType: false, // tell jQuery not to set contentType
        })
          .done((data) => {
            console.log(`ImageCollection / uploadImage: done with ${data}`);
            this.import_button_label = "Uploaded!";
            setTimeout(() => {
              this.import_button_label = "";
            }, 2000);
            this.getAllImages();
          })
          .fail((jqXHR, textStatus) => {
            this.import_button_label = "Échec : " + textStatus;
          });
      }
    },
    getAllImages() {
      fetch(this.content_page)
        .then((response) => response.json())
        .then((json) => {
          console.log(`ImageCollection / mounted: gotten images`);
          console.log(`${JSON.stringify(json, null, 4)}`);
          this.image_collection = json;
          if (this.local_selected_image_name === "") {
            this.local_selected_image_name = this.image_collection[0].url;
          }
        })
        .catch(function (ex) {
          console.log("parsing failed", ex);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.m_imageCollection {
  width: 100%;
  max-height: 260px;
  overflow-y: scroll;
  border-radius: 4px;

  $scrollbar-height: 4px;
  $scrollbar-padding: 6px;

  &::-webkit-scrollbar {
    height: (($scrollbar-padding * 2) + $scrollbar-height);
    width: (($scrollbar-padding * 2) + $scrollbar-height);
    background-color: rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border: 10px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    &:hover {
      border: $scrollbar-padding solid rgba(255, 255, 255, 0);
    }
  }

  display: flex;
  flex-flow: row wrap;
  background-color: #333;

  .m_imageCollection--vignette {
    position: relative;
    flex: 0 0 33.33%;
    flex: 0 0 88px;
    height: 88px;
    padding: 0.5rem;

    input {
      position: absolute;
      bottom: 15px;
      left: 15px;
      z-index: 1;

      &:focus,
      &:activer {
        outline: 0;
        box-shadow: none;
      }
    }

    label {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      margin: 0;
      cursor: pointer;
      border-radius: 4px;
      transition: all 0.4s;
    }

    &.m_imageCollection--vignette_import {
      label {
        width: 100%;
        height: 100%;
        background-color: transparent;
        color: black;
        background-color: #eee;
        border: none;
        padding: 0;
        opacity: 1;

        display: flex;
        flex-flow: column wrap;
        justify-content: center;

        text-align: center;

        &:focus {
          outline: 0;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      margin: 0;
      border-radius: 4px;
      background-color: white;
      transition: all 2s;
    }

    input:checked,
    :hover {
      & + label {
        opacity: 1;

        img {
          background-color: white;
        }
      }
    }
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
}

.inputfile:focus + label,
.inputfile + label:hover {
}
.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
}
</style>
