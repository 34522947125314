<template>
  <Card class="no_max_height">
    <div slot="body">
      <div class="m_imageInput">
        <div ref="imageCropper"></div>
        <div ref="debugView"></div>
      </div>
    </div>
    <div slot="footer">
      <label for="homothetie" class="btn_small">
        <input
          id="homothetie"
          type="checkbox"
          v-model="localCrop.homothetie"
        />&nbsp;Homothetic
      </label>
      <button v-if="localCrop.enabled" class="btn_small" @click="resetcrop">
        Reset the crop
      </button>
    </div>
  </Card>
</template>
<script>
import Card from "./Card.vue";
import p5 from "p5";
import "p5/lib/addons/p5.dom";
import color from "color";

let cached_pixel_crop = [];

export default {
  props: {
    xcount: Number,
    ycount: Number,
    imageRef: {
      type: String,
      default: "",
    },
    ratio: {
      type: Number,
      default: 1,
    },

    crop_value: Object,

    saturation: Number,
    contrast: Number,
    luminosity: Number,
    invert: Boolean,
    blur: Number,
    color_filter: Object,

    autoUpdate: Boolean,
  },
  components: {
    Card,
  },
  data() {
    return {
      sketch: null,
      localCrop: {
        x: 30,
        y: 30,
        width: 80,
        height: 80,
        homothetie: false,
        enabled: true,
      },
      dragPos: {
        x: 0,
        y: 0,
      },
    };
  },
  mounted() {
    console.log("ImageInput / mounted");
    this.$eventHub.$on("scanCanvas", this.scanCanvas);

    if (this.crop_value !== undefined && this.crop_value.enabled) {
      this.localCrop = Object.assign({}, this.crop_value);
    }
    this.startP5Sketch();
  },
  beforeDestroy() {
    console.log("ImageInput / beforeDestroy");
    this.$eventHub.$off("scanCanvas", this.scanCanvas);
  },

  watch: {
    xcount: function () {
      console.log(`ImageInput / watch: xcount`);
      cached_pixel_crop = [];
    },
    ycount: function () {
      console.log(`ImageInput / watch: ycount`);
      cached_pixel_crop = [];
    },
    "localCrop.homothetie": function () {
      if (!this.sketch) {
        return;
      }
      console.log(`ImageInput / watch: localCrop.homothetie`);
      this.sketch.sanitizeCrop();
    },
    allFilters: function () {
      console.log(`ImageInput / watch: allFilters`);
      cached_pixel_crop = [];
      this.updatePreviewSketch();
    },
    imageRef: function () {
      console.log(`ImageInput / watch: imageRef`);

      cached_pixel_crop = [];
      this.startP5Sketch();
    },
  },
  computed: {
    allFilters() {
      console.log("ImageInput / computed: allFilters");
      return (
        this.saturation,
        this.contrast,
        this.luminosity,
        this.invert,
        this.blur,
        this.color_filter.enabled,
        this.color_filter.color,
        this.color_filter.density,
        Date.now()
      );
    },
  },
  methods: {
    scanCanvas() {
      if (!this.sketch) {
        return;
      }
      console.log("ImageInput / methods: scanCanvas");
      let pixelArray = this.sketch.scanImage();
      if (!pixelArray) {
        return;
      }
      this.$eventHub.$emit("updatedPixelAttributes", pixelArray);
    },
    resetcrop() {
      console.log(`ImageInput / methods: resetcrop`);
      this.localCrop.enabled = false;
      this.sketch.sanitizeCrop();
      this.sketch.draw();
    },
    updatePreviewSketch() {
      if (!this.sketch) {
        return;
      }
      console.log(`ImageInput / methods: updatePreviewSketch`);
      this.sketch.updatePreviewImage();
      this.sketch.draw();
    },
    updateCropValues(val) {
      console.log(`ImageInput / methods: update:crop_values`);
      // this.$eventHub.$emit('updateCrop', val);
      this.$emit("update:crop_value", val);
    },
    startP5Sketch() {
      console.log("ImageInput / methods: startP5Sketch");
      if (this.imageRef === "") {
        return;
      }

      let scan_after_init = true;
      // if we are just replacing the canvas, let’s not rescan it — only do it on first run
      if (this.sketch !== null) {
        scan_after_init = false;
      }

      this.sketch = null;

      let imageCrop_container = this.$refs.imageCropper;

      // let’s empty this whole thing
      imageCrop_container.innerHTML = "";

      // Sketch scope
      let sketch = (p) => {
        console.log(`ImageInput / sketch: booting`);

        let source_image;
        let previewed_effect_image;
        let preload_is_finished = false;

        p.preload = () => {
          console.log(`ImageInput / sketch: preload`);
          source_image = p.loadImage(this.imageRef);
          preload_is_finished = true;
        };

        // Setup function
        // ======================================
        p.setup = () => {
          console.log(`ImageInput / sketch: setup`);

          p.pixelDensity(1);

          let image_ratio = source_image.height / source_image.width;

          if (this.localCrop.homothetie) {
            this.localCrop.height = this.localCrop.width * image_ratio;
          }

          let canvas = p.createCanvas(279, 279 * image_ratio);
          canvas.mousePressed(p._mousePressed);
          canvas.mouseMoved(p._mouseMoved);
          canvas.mouseReleased(p.sanitizeCrop);

          previewed_effect_image = p.createGraphics(p.width, p.height);
          p.updatePreviewImage();

          p.noLoop();

          p.sanitizeCrop();
          p.draw();

          if (this.autoUpdate || scan_after_init) {
            this.scanCanvas();
          }
        };

        p.draw = () => {
          console.log(`ImageInput / sketch: draw`);
          p.background(255);
          p.image(previewed_effect_image, 0, 0, p.width, p.height);
          if (this.localCrop.enabled === true) {
            p.stroke("#ff0000");
            p.noFill();
            p.fill(255, 0, 0, 40);
            p.rect(
              this.localCrop.x,
              this.localCrop.y,
              this.localCrop.width,
              this.localCrop.height
            );
          }
        };

        p._mousePressed = () => {
          if (
            p.mouseX > this.localCrop.x &&
            p.mouseX < this.localCrop.x + this.localCrop.width &&
            p.mouseY > this.localCrop.y &&
            p.mouseY < this.localCrop.y + this.localCrop.height
          ) {
            p.dragCropStart();
          } else {
            p.updateCropStart();
          }
        };

        p._mouseMoved = () => {
          if (
            p.mouseX > this.localCrop.x &&
            p.mouseX < this.localCrop.x + this.localCrop.width &&
            p.mouseY > this.localCrop.y &&
            p.mouseY < this.localCrop.y + this.localCrop.height
          ) {
            p.cursor(p.HAND);
          } else {
            p.cursor(p.CROSS);
          }

          if (!p.mouseIsPressed) {
            return;
          }
          if (p.mouseMode === "drag") {
            p.updateCropPosition();
          } else if (p.mouseMode === "crop") {
            p.updateCropSize();
          }
        };

        p.dragCropStart = () => {
          console.log(
            `ImageInput / sketch: dragCropStart — ${p.mouseIsPressed} ${p.mouseX} ${p.mouseY}`
          );
          this.dragPos.x = this.localCrop.x - p.round(p.mouseX);
          this.dragPos.y = this.localCrop.y - p.round(p.mouseY);
          p.mouseIsPressed = true;
          p.mouseMode = "drag";
          p.updateCropPosition();
          p.draw();
        };
        p.updateCropPosition = () => {
          console.log(`ImageInput / sketch: updateCropPosition`);
          this.localCrop.x = this.dragPos.x + p.round(p.mouseX);
          this.localCrop.y = this.dragPos.y + p.round(p.mouseY);
          p.draw();
        };

        p.updateCropStart = () => {
          console.log(
            `ImageInput / sketch: updateCropStart — ${p.mouseIsPressed} ${p.mouseX} ${p.mouseY}`
          );
          this.localCrop.x = p.round(p.mouseX);
          this.localCrop.y = p.round(p.mouseY);
          this.localCrop.enabled = true;
          p.mouseIsPressed = true;
          p.mouseMode = "crop";
          p.updateCropSize();
          p.draw();
        };
        p.updateCropSize = () => {
          if (!p.mouseIsPressed) {
            return;
          }
          console.log(`ImageInput / sketch: updateCropSize`);
          this.localCrop.width = p.round(p.mouseX) - this.localCrop.x;
          this.localCrop.height = p.round(p.mouseY) - this.localCrop.y;

          if (this.localCrop.homothetie) {
            if (
              (this.localCrop.height < 0 && this.localCrop.width > 0) ||
              (this.localCrop.height > 0 && this.localCrop.width < 0)
            ) {
              this.localCrop.height = -this.localCrop.width / this.ratio;
            } else {
              this.localCrop.height = this.localCrop.width / this.ratio;
            }
          }
          p.draw();
        };
        p.sanitizeCrop = () => {
          console.log(`ImageInput / sketch: sanitizeCrop`);
          p.mouseIsPressed = false;
          p.mouseMode = "";

          if (this.localCrop.width < 0) {
            this.localCrop.x += this.localCrop.width;
            // this.localCrop.y += this.localCrop.height;
            this.localCrop.width = p.abs(this.localCrop.width);
            // this.localCrop.height = p.abs(this.localCrop.height);
          }

          if (this.localCrop.height < 0) {
            // this.localCrop.x += this.localCrop.width;
            this.localCrop.y += this.localCrop.height;
            // this.localCrop.width = p.abs(this.localCrop.width);
            this.localCrop.height = p.abs(this.localCrop.height);
          }

          p.draw();
          cached_pixel_crop = [];
          // this.$eventHub.$emit('suggestUpdates');

          // incompréhensible : activer cette ligne map this.localCrop sur values.image.crop
          let val = Object.assign({}, this.localCrop);
          this.updateCropValues(val);

          if (this.autoUpdate) {
            this.scanCanvas();
          }
        };

        p.scanImage = () => {
          console.log(`ImageInput / sketch: scanImage`);
          if (!preload_is_finished) {
            return false;
          }

          // check if cache exists, return it if so
          if (cached_pixel_crop.length > 0) {
            console.log(`ImageInput / scanImage : has cache, returning it`);
            return cached_pixel_crop;
          } else {
            console.log(
              `ImageInput / scanImage : no cache, recreating ref pixels`
            );
          }

          // make a pgraphics of the size xcount and ycount
          let pg = p.createGraphics(this.xcount, this.ycount);

          let cropPercent = {};
          if (this.localCrop.enabled) {
            cropPercent = {
              x: this.localCrop.x / p.width,
              y: this.localCrop.y / p.height,
              width: this.localCrop.width / p.width,
              height: this.localCrop.height / p.height,
            };
          }

          let effect_cropped_image = p.returnImageWithEffects({
            resolution: "full",
            cropPercent,
          });
          if (!effect_cropped_image) {
            return [];
          }
          pg.image(effect_cropped_image, 0, 0, pg.width, pg.height);

          // show pg we’ll use to read pixels value
          // let pg_debug_view = document.createElement('img');
          // pg_debug_view.src = pg.canvas.toDataURL();
          // pg_debug_view.style.width = pg.width;
          // pg_debug_view.style.height = pg.height;
          // this.$refs.debugView.innerHTML = '';
          // this.$refs.debugView.appendChild(pg_debug_view);

          pg.loadPixels();
          cached_pixel_crop = pg.pixels;
          pg.remove();
          //p.updatePixels();
          return cached_pixel_crop;
        };

        p.updatePreviewImage = () => {
          console.log(`ImageInput / sketch: updatePreviewImage`);
          previewed_effect_image = p.returnImageWithEffects();
        };

        p.returnImageWithEffects = (options = {}) => {
          console.log(
            `ImageInput / sketch: returnImageWithEffects with options ${JSON.stringify(
              options,
              null,
              4
            )}`
          );
          var t0 = performance.now();

          let filtered_image;
          if (options.resolution === "full") {
            filtered_image = p.createGraphics(
              source_image.width,
              source_image.height
            );
          } else {
            filtered_image = p.createGraphics(p.width, p.height);
          }

          if (
            Object.prototype.hasOwnProperty.call(options, "cropPercent") &&
            Object.keys(options.cropPercent).length > 0 &&
            !(
              Number.isNaN(options.cropPercent.x) ||
              Number.isNaN(options.cropPercent.y) ||
              Number.isNaN(options.cropPercent.width) ||
              Number.isNaN(options.cropPercent.height)
            )
          ) {
            console.log(
              `ImageInput / sketch: returnImageWithEffects WITH CROP`
            );

            filtered_image = source_image.get(
              options.cropPercent.x * source_image.width,
              options.cropPercent.y * source_image.height,
              options.cropPercent.width * source_image.width,
              options.cropPercent.height * source_image.height
            );
          } else {
            console.log(`ImageInput / sketch: returnImageWithEffects NO CROP`);
            filtered_image.image(
              source_image,
              0,
              0,
              filtered_image.width,
              filtered_image.height
            );
          }

          let filters_local = {
            saturation: this.saturation,
            luminosity: this.luminosity,
            contrast: this.contrast,
            invert: this.invert,
            color_filter: this.color_filter,
          };

          console.log(
            `ImageInput / sketch: returnImageWithEffects • filtered_image not valid`
          );

          filtered_image.loadPixels();

          for (var y = 0; y < filtered_image.height; y++) {
            for (var x = 0; x < filtered_image.width; x++) {
              // get index of pixel
              let i = (x + y * filtered_image.width) * 4;

              var r = filtered_image.pixels[i + 0];
              var g = filtered_image.pixels[i + 1];
              var b = filtered_image.pixels[i + 2];

              if (r === undefined) {
                continue;
              }

              let c = color({ r, g, b });
              if (filters_local.saturation !== 0) {
                let saturation_val = filters_local.saturation / 100;
                c = c.saturate(saturation_val);
              }

              if (filters_local.luminosity !== 0) {
                let luminosity_val = filters_local.luminosity / 100;
                c = c.lighten(luminosity_val);
              }

              if (filters_local.contrast !== 0) {
                // from https://stackoverflow.com/a/37714937
                let contrast = filters_local.contrast / 100 + 1; //convert to decimal & shift range: [0..2]
                let intercept = 128 * (1 - contrast);

                c = color({
                  r: c.red() * contrast + intercept,
                  g: c.green() * contrast + intercept,
                  b: c.blue() * contrast + intercept,
                });
              }

              if (filters_local.invert) {
                c = c.negate();
              }

              if (filters_local.color_filter.enabled) {
                let localDensity = filters_local.color_filter.density / 100;
                c = c.mix(
                  color(filters_local.color_filter.color),
                  localDensity
                );
              }

              filtered_image.pixels[i + 0] = c.red();
              filtered_image.pixels[i + 1] = c.green();
              filtered_image.pixels[i + 2] = c.blue();
            }
          }
          filtered_image.updatePixels();

          if (this.blur !== 0) {
            filtered_image.filter(p.BLUR, this.blur);
          }

          var t1 = performance.now();
          console.log(
            `App / returnImageWithEffects — generation took ${
              t1 - t0
            } millisecondes.`
          );

          // right after returning, we won’t need the canvas elements of that graphics
          this.$nextTick(() => {
            document
              .querySelectorAll("body > canvas")
              .forEach((e) => e.parentNode.removeChild(e));
          });

          return filtered_image;
        };
      };

      this.sketch = new p5(sketch, imageCrop_container);
    },
  },
};
</script>
<style lang="scss">
.m_imageInput {
  min-height: 2em;
  canvas {
    outline: 1px solid #ddd;
  }
}
.card--body {
  max-height: none;
}
</style>
