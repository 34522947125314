import Vue from "vue";
import App from "./App.vue";

Vue.prototype.$eventHub = new Vue(); // Global event bus
Vue.config.productionTip = false;

window.getFormattedTime = (day = new Date()) => {
  var y = day.getFullYear();
  var month = day.getMonth() + 1;
  var d = day.getDate();
  var h = day.getHours();
  var min = day.getMinutes();
  var s = day.getSeconds();
  return `${y}${month}${d}-${h}${min}${s}`;
};
window.getHumanTime = (day = new Date()) => {
  var y = day.getFullYear();
  var month = day.getMonth() + 1;
  var d = day.getDate();
  var h = day.getHours();
  var min = day.getMinutes();
  var s = day.getSeconds();
  return `${h}:${min}:${s} ${d}/${month}/${y}`;
};

window.makeBlobFromSVG = function ({
  svgEl,
  urlToPattern,
  grid_is_ensabled,
  with_palette,
}) {
  let svg_clone = svgEl.cloneNode(true);
  svg_clone.setAttribute("version", "1.1");
  svg_clone.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  svg_clone.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
  svg_clone.getElementById("shapes").setAttribute("transform", "");
  svg_clone.setAttribute("width", svg_clone.dataset.patternwidth);
  svg_clone.setAttribute("height", svg_clone.dataset.patternheight);

  // svg_clone.getElementById("background_rect").remove();

  if (!grid_is_ensabled) {
    svg_clone.getElementById("grid_x").remove();
    svg_clone.getElementById("grid_y").remove();
  }

  if (!with_palette && svg_clone.getElementById("palette"))
    svg_clone.getElementById("palette").remove();

  var svgData = svg_clone.outerHTML;

  if (urlToPattern)
    svgData = svgData.replace(
      /<\/svg>/i,
      `<text x="0" y="-5">${urlToPattern}</text></svg>`
    );

  var preface = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>\r\n';

  var svgBlob = new Blob([preface, svgData], {
    type: "image/svg+xml;charset=utf-8",
  });
  return svgBlob;
};

new Vue({
  render: (h) => h(App),
  data: {
    is_updating: false,
  },
  computed: {
    homeURL() {
      if (window.location.host.includes("localhost"))
        return `http://localhost:8888/pw/2018-bouroullec-api`;
      else return `https://app.bouroullec.com/api`;
    },
  },
}).$mount("#app");
